import React, { createContext, useContext } from 'react';
import { useForm } from './form';

type FormFieldContextType = {
  field: any;
  input?: any;
  properties?: {
    container?: boolean;
    inline?: boolean;
    label?: string;
    placeholder?: string;
  };
  meta?: {
    error?: string;
    touched?: boolean;
    warning?: string;
  };
};

type FieldProviderProps = {
  children: React.ReactNode;
  field: any;
};

const FormFieldContext = createContext<FormFieldContextType>({
  field: {},
});

export const useFormField = () => useContext(FormFieldContext);

const FieldProvider = ({ children, field }: FieldProviderProps) => {
  const { onChange, onBlur, onFocus, formDisabled } = useForm();

  const onFieldChange = (ev: any) => {
    switch (field.technical.component) {
      case 'checkbox':
        onChange(field.technical.name, ev.target.checked);
        break;
      default:
        onChange(field.technical.name, ev.target.value);
        break;
    }
  };

  const onFieldBlur = () => {
    onBlur(field.technical.name);
  };

  const onFieldFocus = () => {
    onFocus(field.technical.name);
  };

  const id = () => {
    if (field.technical.component === 'radio') {
      return field.technical.radioValue;
    }
    return field.technical.name;
  };

  return (
    <FormFieldContext.Provider
      value={{
        field,
        properties: field.properties,
        input: {
          name: field.technical.name,
          type: field.properties.type,
          id: id(),
          value: field?.state?.value || undefined,
          onChange: onFieldChange,
          onBlur: onFieldBlur,
          onFocus: onFieldFocus,
          disabled: formDisabled || field?.state?.disabled,
        },
      }}
    >
      {children}
    </FormFieldContext.Provider>
  );
};

export default FieldProvider;
