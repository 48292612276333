import { ReactNode, useEffect, useState, createContext, useContext } from 'react';
import { io, Socket } from 'socket.io-client';
import { WALKER_DOMAIN } from '../../constants';

type RequestSocketProps = {
  children: ReactNode;
  id: string;
  authorizationToken: string;
  locale: string;
};

type RequestSocketContextType = {
  socket: Socket | null;
  request: any;
  fields: any[];
  onChange: Function;
  onNavigate: Function;
  navigation: any[];
};

type ViewInitType = {
  viewReference: string;
  fields: any[];
  navigation: any[];
};

const RequestSocketContext = createContext<RequestSocketContextType>({
  socket: null,
  request: {},
  fields: [],
  navigation: [],
  onChange: () => {},
  onNavigate: () => {},
});

export const useRequestSocket = () => useContext(RequestSocketContext);

const RequestSocket = ({ children, id, authorizationToken, locale }: RequestSocketProps) => {
  const [socket, setSocket] = useState<Socket | null>(null);
  const [fields, setFields] = useState<any>([]);
  const [viewRef, setViewRef] = useState<any>(null);
  const [navigation, setNavigation] = useState<any>([]);

  useEffect(() => {
    const socketIo = io(WALKER_DOMAIN);
    setSocket(socketIo);
    console.log('[MIA] connectSocket');
    return () => {
      console.log('[MIA] disconnectSocket');
      socketIo.disconnect();
    };
  }, []);

  if (!socket) return null;

  socket.on('connect', () => {
    socket.emit('view:init', { viewId: id, authorizationToken, locale }, ({ viewReference, fields, navigation }: ViewInitType) => {
      setViewRef(viewReference);
      setNavigation(navigation);
      setFields(fields);
    });
  });

  socket.on('view:change', ({ fields, navigation }: ViewInitType) => {
    console.log('[MIA] <-- GOT VIEW CHANGE', fields);
    setNavigation(navigation);
    setFields(fields);
  });

  socket.on('disconnect', () => {
    console.log('[MIA] disconnected');
  });

  const onChange = (path: string, value: any) => {
    console.log('--> CHANGE', path, value);
    socket.emit('view:change', { path, value, viewReference: viewRef }, ({ fields, navigation }: any) => {
      /*console.log('[MIA] <-- GOT NEW FIELDS', fields);
      setFields(fields);
      setNavigation(navigation);*/
    });
  };

  const onNavigate = (pageID: string) => {
    socket.emit('view:navigate', { id, pageID, viewReference: viewRef }, ({ fields, navigation }: any) => {
      console.log('[MIA] <-- GOT NEW PAGE', fields);
      setFields(fields);
      setNavigation(navigation);
    });
  };

  return <RequestSocketContext.Provider value={{ socket, request: {}, fields, onChange, navigation, onNavigate }}>{children}</RequestSocketContext.Provider>;
};

export default RequestSocket;
