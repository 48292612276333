import FieldsCheckbox from 'components/form/fields/checkbox';
import { FieldsRadio } from 'components/form/fields/radio';
import FieldProvider from 'components/form/fieldProvider';
import FieldsInput from 'components/form/fields/input';

type FormFieldProps = {
  field: any;
};

const FormField = ({ field }: FormFieldProps) => {
  let CorrespondingField = null;

  switch (field.technical.component) {
    case 'text':
      CorrespondingField = FieldsInput;
      break;
    case 'checkbox':
      CorrespondingField = FieldsCheckbox;
      break;
    case 'radio':
      CorrespondingField = FieldsRadio;
      break;
    default:
      console.warn('field component not handled', field.technical.component);
      CorrespondingField = FieldsInput;
      break;
  }

  return (
    <FieldProvider field={field}>
      <CorrespondingField />
    </FieldProvider>
  );
};

export default FormField;
