import classNames from 'classnames';
import ErrorMessage from 'components/form/ui/errorMessage';
import { useFormField } from 'components/form/fieldProvider';
import WarningMessage from 'components/form/ui/warningMessage';
import { fieldWrapper } from 'components/form/decorators/fieldWrapper';

const FieldsInput = () => {
  const { input, properties } = useFormField();
  return (
    <>
      <input {...input} value={input.value || ''} className={classNames('field-input')} placeholder={properties?.placeholder || ''} />
      <ErrorMessage />
      <WarningMessage />
    </>
  );
};

export default fieldWrapper()(FieldsInput);
