import React from 'react';
import RequestShow from './components/request/show';

function App() {
  const decoded = decodeURIComponent(window.location.search);
  const urlParams = new URLSearchParams(decoded);

  const viewId = urlParams.get('viewId') || 'viewId';
  const authorizationToken = urlParams.get('authorizationToken') || 'authtoken';
  const locale = urlParams.get('locale') || 'en';

  return (
    <div className="App">
      <RequestShow id={viewId} authorizationToken={authorizationToken} locale={locale} />
    </div>
  );
}

export default App;
