import RequestSocket from 'components/request/socket';
import RequestForm from './form';
import RequestNavigation from './navigation';
import './show.scss';

type RequestShowProps = {
  id: string;
  authorizationToken: string;
  locale: string;
};

const RequestShow = ({ id, authorizationToken, locale }: RequestShowProps) => {
  return (
    <RequestSocket id={id} authorizationToken={authorizationToken} locale={locale}>
      <div className="request-container">
        <RequestNavigation />
        <RequestForm />
      </div>
    </RequestSocket>
  );
};

export default RequestShow;
